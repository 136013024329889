import { ManagementService } from "~/app/management/management.service";
import {
  ProjectReply,
  ProjectStatus,
} from "~/models/textToSpeech/project.model";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Input,
} from "@angular/core";
import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { waitUntil } from "~/utils/waitUntil";
import { UploadFileComponent } from "~/app/shared/components/upload-file/upload-file.component";
import { FileUploadContext } from "../create-project/create-project.component";
import { UserHelperService } from "~/app/shared/userHelper.service";
import { DialogUploadFileComponent } from "~/app/shared/components/dialog-upload-file/dialog-upload-file.component";
import { DialogConfirmReplaceComponent } from "~/app/shared/components/dialog-confirm-replace/dialog-confirm-replace.component";
import { Project, VoiceType } from "@models/textToSpeech/project.model";
import { ProjectService } from "../../project.service";
import { Status } from "@models/upload/file.model";
import { AccountService } from "~/app/account/account.service";
import Recordings from "wavesurfer.js/dist/plugins/record";
import { Asset } from "@models/asset/asset.model";
import { DialogConfirmShareProjectComponent } from "~/app/shared/components/dialog-confirm-share-project/dialog-confirm-share-project.component";
import { catchError, throwError } from "rxjs";

@Component({
  selector: "app-script-writing",
  templateUrl: "./script-writing.component.html",
  styleUrls: ["./script-writing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptWritingComponent implements OnInit, AfterViewInit {
  @Input() fileUploadContexts: FileUploadContext[];
  @Input() project: Project;
  @Input() selectedVideoAsset: Asset;
  @Input() voicerType: string;
  @Input() pageId: number;
  @Input() mainButtonName: string;

  @Output() onDrop = new EventEmitter();
  @Output() onFileSelected = new EventEmitter();
  @Output() goBackToProjectSection = new EventEmitter();
  @Output() createProject = new EventEmitter();
  @Output() updateProject = new EventEmitter();
  @Output() restartMix = new EventEmitter();

  @ViewChild("stepper") myStepper: MatStepper;
  @ViewChild(UploadFileComponent)
  public uploadFileComponent: UploadFileComponent;
  private deviceIdFound: string = null;
  public microphoneList: MediaDeviceInfo[] = [];
  private viewSetupGuideEmitted = false;
  public microphoneDeviceId = "";
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public goToNextModule = false;
  public myStepperBackup: MatStepper;
  public spinner = false;
  public userName = "";
  public enableNextButton = false;
  public projectMixStarted = false;
  public retriedCount = 0;
  private events: ProjectReply[] = [];
  private sharedWith: string;

  constructor(
    private _formBuilder: FormBuilder,
    private userHelperService: UserHelperService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private account: AccountService,
    private cdk: ChangeDetectorRef,
    private management: ManagementService
  ) {}

  ngAfterViewInit(): void {
    if (
      this.project?.voiceSetting === VoiceType.Synthetic &&
      this.showScriptWriting
    ) {
      this.goNext();
    } else if (!this.showScriptWriting) {
      this.myStepper.selectedIndex = 0;
      this.myStepperBackup = this.myStepper;
    }
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.myStepperBackup = this.myStepper;

    this.userHelperService.sharedUserName.subscribe(
      (username) => (this.userName = username)
    );
    this.getMicrophoneDetails();

    this.checkForReplies();
    if (this.pageId !== undefined) {
      this.myStepper.selectedIndex = this.pageId;
      this.goNext();
    }
  }

  checkForReplies() {
    this.spinner = true;
    if (this.hasReplies || this.retriedCount === 3) {
      this.spinner = false;
    } else if (!this.hasReplies && this.fileUploadContexts?.length) {
      setTimeout(
        () => {
          this.getProject();
        },
        this.retriedCount > 0 ? 4000 : 0
      );
    } else {
      this.spinner = false;
    }
    this.cdk.detectChanges();
  }

  getProject(): void {
    this.projectService
      .getByUniqueProjectName(this.fileUploadContexts[0].assetId)
      .subscribe({
        next: (result) => {
          this.project = { ...result, ...this.project };
          this.project.replies = result.replies;
          this.cdk.detectChanges();
          this.updateProject.emit(this.project);
          this.retriedCount++;
          this.checkForReplies();
        },
        error: (error) => {
          if (error.error.message.includes("id")) {
            this.retriedCount++;
            this.checkForReplies();
          }
        },
      });
  }

  updateProjectFromRecording(project: Project) {
    this.project = project;
    this.updateProject.emit(this.project);
  }

  async goPrevious() {
    this.goToNextModule = false;
    await waitUntil(() => !!this.myStepper);
    this.myStepper.selectedIndex =
      this.myStepperBackup.selectedIndex === 0
        ? 0
        : this.myStepperBackup.selectedIndex - 1;

    this.myStepperBackup = this.myStepper;
  }

  goNext() {
    this.goToNextModule = true;
    this.myStepperBackup = this.myStepper;
  }

  async goNextStep() {
    if (
      (!this.projectMixStarted &&
        this.myStepper?.selectedIndex === (this.isVoicerSelected ? 2 : 1)) ||
      !this.showScriptWriting
    ) {
      this.projectMixStarted = true;
      this.createProject.emit();
      return;
    }

    if (this.viewSetupGuide && this.viewSetupGuideEmitted) {
      this.exitSetup();
      return;
    }

    this.goToNextModule = false;
    await waitUntil(() => !!this.myStepper);
    if (!this.myStepperBackup) {
      this.myStepperBackup = this.myStepper;
    }

    if (
      this.myStepperBackup.selectedIndex === 1 &&
      this.deviceIdFound !== this.microphoneDeviceId
    ) {
      this.deviceIdFound = this.microphoneDeviceId;
      this.goToNextModule = true;
      this.viewSetupGuideEmitted = false;
      this.updateDeviceId();
      return;
    }

    this.myStepper.selectedIndex = this.myStepperBackup.selectedIndex + 1;
    this.myStepperBackup = this.myStepper;
  }

  get isVoicerSelected(): boolean {
    return this.voicerType === VoiceType.Voicer;
  }

  get isAllRecorded() {
    return this.isVoicerSelected
      ? this.project.replies?.length &&
          this.project.replies.every((reply) => reply.audioFilePath)
      : true;
  }

  get disableNext(): boolean {
    if (this.hadManuallyAddedEvent) {
      return this.myStepperBackup?.selectedIndex
        ? this.isRecordingModuleVisible && this.viewSetupGuide
          ? true
          : !!this.microphoneDeviceId && this.isAllRecorded
        : true;
    }
    if (!this.myStepperBackup?.selectedIndex) {
      return this.isAllFilesUploaded && this.hasReplies;
    } else {
      return this.isRecordingCriteriaSatisfied();
    }
  }

  private isRecordingCriteriaSatisfied(): boolean {
    if (!this.viewSetupGuide) {
      return !!this.microphoneDeviceId && this.isAllRecorded;
    } else {
      return !!this.microphoneDeviceId;
    }
  }

  onFileSelection(event: any) {
    this.spinner = true;
    const eventData = {
      fileData: event,
      uniqueProjectName: this.uniqueProjectName,
    };
    this.onFileSelected.emit(eventData);
  }

  onDropFile(event: any) {
    this.spinner = true;
    const eventData = {
      fileData: event,
      uniqueProjectName: this.uniqueProjectName,
    };
    this.onDrop.emit(eventData);
  }

  get uploadedDate() {
    return new Date(
      (this.fileUploadContexts[0] &&
        this.fileUploadContexts[0].multiPartUploadHandler?.startTime) ||
        this.project.creationDateTime
    );
  }

  get fileName() {
    return this.fileUploadContexts && this.fileUploadContexts.length
      ? this.fileUploadContexts[0].file.name.split(".xml")[0]
      : "";
  }

  get fileNameWithExtension() {
    return this.fileUploadContexts && this.fileUploadContexts.length
      ? this.fileUploadContexts[0].file.name
      : "";
  }

  public goBack() {
    this.goBackToProjectSection.emit(this.viewSetupGuide);
  }

  uploadNewFile() {
    const dialogRef = this.dialog.open(DialogUploadFileComponent, {
      width: "977px",
      height: "463px",
      data: {},
      panelClass: "custom-dialog-class",
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.confirmFileUpload(data);
      }
    });
  }

  confirmFileUpload(fileDetails: any) {
    const data = {
      message: "scriptWriting.overWriteWarningFirstMessage",
      secondMessage: "scriptWriting.overWriteWarningSecondMessage",
      title: "scriptWriting.overWriteScript",
      confirmLabel: "scriptWriting.confirm",
      cancelLabel: "scriptWriting.cancel",
    };

    const dialogRef = this.dialog.open(DialogConfirmReplaceComponent, {
      width: "465px",
      height: "228px",
      panelClass: "custom-dialog-class",
      data,
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      this.spinner = true;
      const eventData = {
        fileData: fileDetails.fileData,
        uniqueProjectName: this.uniqueProjectName,
      };
      if (response && fileDetails.type === "drop") {
        this.onDrop.emit(eventData);
      } else if (response && fileDetails.type === "selection") {
        this.onFileSelected.emit(eventData);
      }
    });
  }

  get title() {
    return this.myStepper &&
      this.myStepper.selectedIndex === 0 &&
      this.showScriptWriting
      ? "scriptWriting.title"
      : this.myStepper &&
        this.myStepper.selectedIndex === 1 &&
        this.isVoicerSelected
      ? "scriptWriting.recording"
      : "scriptWriting.review";
  }

  get uniqueProjectName() {
    return this.fileUploadContexts?.length
      ? this.fileUploadContexts[0].assetId
      : "";
  }

  get isAllFilesUploaded() {
    return (
      (this.fileUploadContexts?.length &&
        this.fileUploadContexts.every(
          (fileUpload) => fileUpload.upload.status === Status.UploadSuccess
        )) ||
      !!this.project?.replies?.length
    );
  }

  get hasReplies() {
    return !!this.project?.replies?.length;
  }

  get showScriptWriting() {
    return (
      this.isVoicerSelected ||
      this.project?.ttmlFilePath === "" ||
      this.project?.isGeneratedTtmlFile
    );
  }

  private getMicrophoneDetails(): void {
    this.getMicOptions();
    this.getMicrophoneDeviceId();
  }

  private getMicrophoneDeviceId(): void {
    this.account.getMicrophoneDeviceId().subscribe((res) => {
      this.microphoneDeviceId = this.deviceIdFound = res.microphoneDeviceId;
    });
  }

  private getMicOptions(): void {
    Recordings.getAvailableAudioDevices().then(
      (devices) => (this.microphoneList = devices)
    );
  }

  private isMicrophoneConfigurationRequired(): boolean {
    return (
      !this.deviceIdFound || !this.isMatchingDeviceIdFound(this.deviceIdFound)
    );
  }

  private updateDeviceId(): void {
    this.account.updateMicrophoneDeviceId(this.microphoneDeviceId).subscribe();
  }

  private isMatchingDeviceIdFound(deviceId: string): boolean {
    const foundDevice = this.microphoneList.find((device: InputDeviceInfo) => {
      return device.deviceId === deviceId;
    });
    return foundDevice !== undefined ? true : false;
  }

  get viewSetupGuide(): boolean {
    return this.viewSetupGuideEmitted
      ? true
      : this.isMicrophoneConfigurationRequired();
  }

  public updateViewSetUpGuide(value: string): void {
    this.viewSetupGuideEmitted = true;
    this.microphoneDeviceId = value;
  }

  public microphoneSelected(value: string): void {
    this.microphoneDeviceId = value;
  }

  public manualEvents(events: ProjectReply[]): void {
    this.events = events;
  }

  private exitSetup() {
    this.viewSetupGuideEmitted = false;
  }

  get isRecordingModuleVisible(): boolean {
    return this.goToNextModule && this.myStepperBackup?.selectedIndex === 1;
  }

  get hadManuallyAddedEvent(): boolean {
    return !!this.events?.length;
  }

  get viewShareProjectButton(): boolean {
    return (
      (!this.myStepperBackup?.selectedIndex ||
        (this.myStepperBackup?.selectedIndex === 1 &&
          !this.isRecordingModuleVisible)) &&
      this.project?.voiceSetting === VoiceType.Voicer
    );
  }

  private getShareId(): string {
    if (this.project?.sharedWith && !this.sharedWith) {
      return this.project?.sharedWith.toString();
    } else if (!this.project.sharedWith) {
      return this.sharedWith ? this.sharedWith.toString() : "";
    } else if (this.project?.sharedWith && this.sharedWith) {
      return this.sharedWith.toString();
    } else {
      return "";
    }
  }

  public shareProject(): void {
    this.spinner = true;
    const shareId = this.getShareId();
    const getUsersObs = this.management.getUsers();
    getUsersObs.subscribe((users) => {
      this.spinner = false;
      const data = {
        title: "scriptWriting.share.title",
        subHeading: "scriptWriting.share.subHeading",
        cancel: "scriptWriting.share.cancel",
        confirm: "scriptWriting.share.confirm",
        list: users.map((user) => ({
          value: user.id,
          key: user.name,
        })),
        defaultValue: shareId,
      };
      this.dialog
        .open(DialogConfirmShareProjectComponent, {
          width: "550px",
          data: data,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(({ value }) => {
          if (value) {
            this.spinner = true;
            this.projectService
              .shareProjectForVoicer(+value, this.project.uniqueProjectName)
              .pipe(catchError((err: any) => throwError(err)))
              .subscribe(() => (this.sharedWith = value))
              .add(() => (this.spinner = false));
          }
        });
    });
  }

  public hideRestartMixButton() {
    return (
      !!this.project.errorMessage ||
      this.project.status !== ProjectStatus.ReadyToDownload
    );
  }

  public projectRestartMix() {
    this.restartMix.emit();
  }
}
