import { EventEmitter, Input } from "@angular/core";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Status } from "@models/upload/file.model";
import { FormPageService } from "~/app/base/formPage.service";
import { FileUploadContext } from "~/app/textToSpeech/pages/create-project/create-project.component";
import { environment } from "~/environments/environment";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  @Input() fromDropbox = false;
  @Input() isScriptWriting = false;
  @Output() onFileSelection = new EventEmitter();
  @Output() onDrop = new EventEmitter();
  @Input() isDashedBorder = false;
  @ViewChild("fileUploadInput")
  public fileUploadInput: ElementRef;

  public uploadCompleted = false;
  public status = Status;
  public fileUploadContexts: FileUploadContext[] = [];
  public filesAllowedWarning = false;
  public authorizedExtensions = "";
  public fileUploadMaxSize = environment.project.maximumFileSize / 1000000000;
  private allowXmlOnly = false;

  constructor(
    private cdk: ChangeDetectorRef,
    protected router: Router,
    protected formPageService: FormPageService
  ) {}

  ngOnInit() {}

  public dragOverHandler($event: DragEvent): boolean {
    $event.preventDefault();
    return false;
  }

  public dragLeaveHandler($event: DragEvent): boolean {
    $event.preventDefault();
    return false;
  }

  public selectFiles(isOnlyXmlAllowed: boolean) {
    this.allowXmlOnly = isOnlyXmlAllowed;
    this.setAuthorizedFileExtensions();
    this.cdk.detectChanges();
    this.fileUploadInput.nativeElement.click();
  }

  get isMultipleExtensionsAllowed(): boolean {
    return this.authorizedExtensions.split(",").length >= 2;
  }

  public setAuthorizedFileExtensions(): void {
    const allowedExtension = this.fromDropbox
      ? [".xml", ".wav", ".mp4", ".mov", ".wmv", "mxf", ".ts", ".mpg", ".mpeg"]
      : [".xml", ".wav"];
    const extensions = this.allowXmlOnly
      ? environment.project.authorizedFileExtensions.filter(
          (ext) => ext === ".xml"
        )
      : environment.project.authorizedFileExtensions.filter((ext) =>
          allowedExtension.includes(ext)
        );
    this.authorizedExtensions = extensions.join(", ");
  }

  public navigateToAssetSelection() {
    this.formPageService.setAutoRestore(true);
    this.router.navigate([
      "/video-asset-selection",
      {
        backLabel: "createProject.backTo",
      },
    ]);
  }

  public async onFileSelected(event: any) {
    this.onFileSelection.emit(event);
  }

  public async dropHandler(event: DragEvent) {
    this.onDrop.emit(event);
  }

  public get videoFileExtensions(): string {
    return [...environment.authorizedFileExtensions.video].join(", ");
  }

  public get audioFileExtensions(): string {
    return [...environment.authorizedFileExtensions.audio].join(", ");
  }

  public get canSelectFiles() {
    return (
      this.fileUploadContexts.length === 0 ||
      (this.fileUploadContexts.length > 0 &&
        this.fileUploadContexts.length ===
          this.fileUploadContexts.filter(
            (fuc) => fuc.upload.status === Status.UploadSuccess
          ).length)
    );
  }
}
