import {
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  AfterViewInit,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
  Project,
  ProjectReply,
  VoiceType,
} from "@models/textToSpeech/project.model";
import {
  getTimeInSecondsFromFrameRate,
  secondsTimeToFramesStartTimeCode,
} from "~/utils/project";
import { WaveForm } from "@models/shared/waveForm.model";
import { Asset } from "@models/asset/asset.model";
import { AssetService } from "~/app/asset/asset.service";
import { waitUntil } from "~/utils/waitUntil";
import { ReplyService } from "../../reply.service";
import { TranslateService } from "@ngx-translate/core";
import { calculateMinuteDifference } from "~/utils/project";
import { ShortcutService } from "~/app/shared/shortcut.service";
import { Action } from "@models/shortcut/shortcut.model";
import { Subscription } from "rxjs";
import { ReplyEventComponent } from "~/app/shared/components/reply-event/reply-event.component";
import { appConfig } from "~/environments/app.config";

enum ActionMove {
  back = "back",
  forth = "forth",
}
@Component({
  selector: "app-script-writing-dashboard",
  templateUrl: "./script-writing-dashboard.component.html",
  styleUrls: ["./script-writing-dashboard.component.scss"],
})
export class ScriptWritingDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() project: Project;
  @Input() uniqueProjectName: string;

  @Input() selectedVideoAsset: Asset;
  @Output() projectReplies = new EventEmitter();
  @ViewChild("timeline") timeline: any;
  @ViewChild("replysEvents", { static: false })
  replysEvents: ReplyEventComponent;

  @ViewChild("player")
  private player: any;

  public replyForm: FormGroup;

  private waveForms: WaveForm[];
  private lastSavedDateTime: Date;

  private shortcutTriggeredSubscription: Subscription;
  private currentTimeCode: string = "00:00:00:00";
  private prevSelectedEventIndex = 0;
  @ViewChild("eventSection") eventSection!: ElementRef;

  constructor(
    private assetService: AssetService,
    private fb: FormBuilder,
    private replyService: ReplyService,
    private translateService: TranslateService,
    private keyboardShortcutService: ShortcutService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.setDynamicHeight();
  }

  ngOnInit(): void {
    this.replyForm = this.fb.group({
      events: this.fb.array([]), // Initialize an empty FormArray
    });
    this.sortReplies();
    this.addExistingEvent();
    if (this.selectedVideoAsset?.id) {
      this.assetService
        .getTimeLinePeaks(this.selectedVideoAsset?.id)
        .subscribe((data) => {
          this.waveForms = data;
        });
    }

    this.currentTimeCode = this.selectedVideoAsset?.startTimeCode
      ? this.selectedVideoAsset?.startTimeCode
      : "00:00:00:00";

    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.NewEvent) {
          this.addEvent();
        } else if (shortcut === Action.NewEventTcIn) {
          this.addEventTcIn();
        } else if (shortcut === Action.NextEvent) {
          this.moveBackAndForth(ActionMove.forth);
        } else if (shortcut === Action.PreviousEvent) {
          this.moveBackAndForth(ActionMove.back);
        } else if (shortcut === Action.GoToTop) {
          this.goToFirstEvent();
        } else if (shortcut === Action.GoToBottom) {
          this.goToLastEvent();
        } else if (shortcut === Action.Delete) {
          this.removeEvent(this.selectedEventIndex);
        } else if (
          shortcut === Action.OutTimecode ||
          shortcut === Action.InTimecode
        ) {
          this.currentTimeCode = secondsTimeToFramesStartTimeCode(
            this.player._player.currentTime(),
            this.selectedVideoAsset,
            this.project
          );
        } else if (shortcut === Action.Save) {
          this.updateEvent(this.selectedEventIndex);
        }
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.setDynamicHeight();
  }

  private setDynamicHeight(): void {
    this.eventSection.nativeElement.style.height = this.timeline
      ? `54vh`
      : `60vh`;
  }

  getAssetTitle() {
    return this.selectedVideoAsset?.title || "";
  }

  get waveForm(): WaveForm[] {
    return this.waveForms || [];
  }

  get startTimeCode(): string {
    return this.project.startTimeCode !== "00:00:00:00"
      ? this.project.startTimeCode.replace(";", ":")
      : this.selectedVideoAsset.startTimeCode
      ? this.selectedVideoAsset.startTimeCode.replace(";", ":")
      : "00:00:00:00";
  }

  public timelineSeek(event: any): void {
    this.player._player.currentTime(event);
  }

  ngOnDestroy(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
  }

  public sortReplies() {
    if (!this.project?.replies) {
      this.project.replies = [];
    }
    this.project?.replies?.sort(
      (eventA, eventB) =>
        getTimeInSecondsFromFrameRate(eventA.tcIn, this.project.frameRate) -
        getTimeInSecondsFromFrameRate(eventB.tcIn, this.project.frameRate)
    );
  }

  public async onPlayerInit(): Promise<void> {
    await waitUntil(() => !!this.timeline);
  }

  public isTimeInRange(checkTime: string, startTime: string, endTime: string) {
    const checkSeconds = getTimeInSecondsFromFrameRate(
      checkTime,
      this.project.frameRate
    );
    const startSeconds = getTimeInSecondsFromFrameRate(
      startTime,
      this.project.frameRate
    );
    const endSeconds = getTimeInSecondsFromFrameRate(
      endTime,
      this.project.frameRate
    );

    return startSeconds <= checkSeconds && checkSeconds <= endSeconds;
  }

  public checkFramesBetweenEvents(
    checkTime: string,
    startTime: string,
    endTime: string
  ) {
    const checkSeconds = getTimeInSecondsFromFrameRate(
      checkTime,
      this.project.frameRate
    );
    const startSeconds = getTimeInSecondsFromFrameRate(
      startTime,
      this.project.frameRate
    );
    const endSeconds = getTimeInSecondsFromFrameRate(
      endTime,
      this.project.frameRate
    );

    const interval = appConfig.framesBetweenEvents / this.project.frameRate;

    return (
      startSeconds + interval <= checkSeconds &&
      checkSeconds <= endSeconds + interval
    );
  }

  public getOverlapMessage(data: {
    index: number;
    reply: ProjectReply;
  }): string {
    if (data.reply.tcIn && data.reply.tcOut) {
      let overlapMessage = "";

      if (data.reply.tcIn === data.reply.tcOut) {
        return this.translateService
          .instant("timeline.overLappedMessage")
          .replace("{0}", data.index + 1);
      }

      for (let i = 0; i < this.events?.controls?.length; i++) {
        if (i < data.index) {
          let isTcOutRange;
          const projectReply = this.events.value[i];
          const isTcInRange = this.isTimeInRange(
            data.reply.tcIn,
            projectReply.tcIn,
            projectReply.tcOut
          );

          if (!isTcInRange) {
            isTcOutRange = this.isTimeInRange(
              data.reply.tcOut,
              projectReply.tcIn,
              projectReply.tcOut
            );
          }

          overlapMessage =
            isTcInRange || isTcOutRange
              ? this.translateService
                  .instant("timeline.overLappedMessage")
                  .replace("{0}", i + 1)
              : "";

          if (overlapMessage) {
            return overlapMessage;
          }

          let isTcOutMinimumFramesBetweenEvents;
          const isTcInMinimumFramesBetweenEvents =
            this.checkFramesBetweenEvents(
              data.reply.tcIn,
              projectReply.tcIn,
              projectReply.tcOut
            );

          if (!isTcInMinimumFramesBetweenEvents) {
            isTcOutMinimumFramesBetweenEvents = this.checkFramesBetweenEvents(
              data.reply.tcOut,
              projectReply.tcIn,
              projectReply.tcOut
            );
          }

          overlapMessage =
            isTcInMinimumFramesBetweenEvents ||
            isTcOutMinimumFramesBetweenEvents
              ? this.translateService
                  .instant("timeline.minimumFramesBetweenEventsMessage")
                  .replace("{0}", i + 1)
              : "";

          if (overlapMessage) {
            return overlapMessage;
          }
        }
      }
    }
    return "";
  }

  addExistingEvent() {
    this.project?.replies?.forEach((reply: ProjectReply, index: number) => {
      const event = this.fb.group({
        tcIn: [reply.tcIn],
        tcOut: [reply.tcOut],
        ttmlSegmentData: [reply.ttmlSegmentData],
        isLongAudio: [this.isLongAudio({ index, reply })],
        isSelected: [index === 0 || reply.isSelected],
        isHovered: [false],
        overlapMessage: [this.getOverlapMessage({ index, reply })],
        isPlayable: [false],
        isBeingPlayed: [false],
        isFirstPlayback: [true],
        isNew: [false],
      });

      // Add the new form group to the FormArray
      this.events.push(event);
    });
  }

  addEvent() {
    this.events.controls.forEach((item) => {
      item.get("isSelected").setValue(false);
    });

    const event = this.fb.group({
      tcIn: ["00:00:00:00"],
      tcOut: ["00:00:00:00"],
      ttmlSegmentData: [""],
      isLongAudio: [false],
      isSelected: [true],
      isHovered: [false],
      overlapMessage: [""],
      isPlayable: [false],
      isBeingPlayed: [false],
      isFirstPlayback: [true],
      isNew: [true],
    });
    // Add the new form group to the FormArray
    this.events.push(event);
    this.cdr.detectChanges();
    this.selectEventOnTimeline(this.events.length - 1);
  }

  addEventTcIn() {
    this.events.controls.forEach((item) => {
      item.get("isSelected").setValue(false);
    });

    const currentTimeCode = secondsTimeToFramesStartTimeCode(
      this.player._player.currentTime(),
      this.selectedVideoAsset,
      this.project
    );
    const event = this.fb.group({
      tcIn: [currentTimeCode],
      tcOut: ["00:00:00:00"],
      ttmlSegmentData: [""],
      isLongAudio: [false],
      isSelected: [true],
      isHovered: [false],
      overlapMessage: [""],
      isPlayable: [false],
      isBeingPlayed: [false],
      isFirstPlayback: [true],
      isNew: [true],
    });
    // Add the new form group to the FormArray
    this.events.push(event);
    this.selectEventOnTimeline(this.events.length - 1);
  }

  get events() {
    return this.replyForm.get("events") as FormArray;
  }

  insertEvent(index: number) {
    this.project.replies[index].speedRate = this.project.speedRate;
    this.replyService.save(this.project.replies[index]).subscribe((data) => {
      if (data) {
        this.project.replies[index].id = data.id;
        this.selectEventOnTimeline(this.selectedEventIndex);
        if (this.timeline) {
          this.timeline.refreshRegions(this.selectedEventIndex);
        }
        this.lastSavedDateTime = new Date();
      }
    });
  }

  updateEvent(index: number) {
    this.project.replies[index].speedRate = this.project.speedRate;
    this.replyService.edit(this.project.replies[index]).subscribe((data) => {
      if (data) {
        this.selectEventOnTimeline(this.selectedEventIndex);
        this.lastSavedDateTime = new Date();
        this.project.replies[index] = data;
        if (!this.timeline) return;
        this.timeline.refreshRegions(this.selectedEventIndex);
        this.projectReplies.emit(this.project.replies);
      }
    });
  }

  removeEvent(index: number) {
    this.events.removeAt(index);
    if (this.project.replies[index]?.id) {
      this.replyService
        .delete(this.project.id, this.project.replies[index]?.id)
        .subscribe(() => {
          this.project.replies.splice(index, 1);
          this.setNextTcInForEvents();
          this.updateControls();
          if (this.timeline)
            this.timeline.refreshRegions(this.selectedEventIndex);
          this.projectReplies.emit(this.project.replies);
        });
    }
  }

  updateControls() {
    for (let i = 0; i < this.events.controls.length; i++) {
      this.events.controls[i].get("overlapMessage").setValue(
        this.getOverlapMessage({
          index: i,
          reply: this.events.controls[i].value,
        })
      );
    }
  }

  /** If the event is 1 sec, we can only write 15 characters,
   * each second added to the event allows for 15 additional characters to that event.
   * The colour changes to red if we bust the allotted characters
   **/
  isLongAudio(data: { index: number; reply: ProjectReply }): boolean {
    if (data.reply.tcIn && data.reply.tcOut) {
      const tcInSeconds = getTimeInSecondsFromFrameRate(
        data.reply.tcIn,
        this.project.frameRate
      );
      const tcOutSeconds = getTimeInSecondsFromFrameRate(
        data.reply.tcOut,
        this.project.frameRate
      );
      const differenceInSeconds = Number(tcOutSeconds) - Number(tcInSeconds);
      if (this.project.voiceSetting === VoiceType.Voicer) {
        const wordsPerSecond = this.project.voicerReadingSpeed / 60;
        const wordsCapacityForTC = Math.round(
          differenceInSeconds * wordsPerSecond
        );
        const totalWords = data.reply.ttmlSegmentData.split(" ").length || 0;
        return totalWords > wordsCapacityForTC;
      }
      const segmentLength = data.reply.ttmlSegmentData.length;
      const totalSecondsForSegment = segmentLength / 15;

      return totalSecondsForSegment > differenceInSeconds;
    }

    return false;
  }

  sortRepliesAndControls() {
    this.sortReplies();
    this.events.controls.sort((a, b) => {
      if (
        a.get("tcOut").value === "00:00:00:00" ||
        a.get("ttmlSegmentData").value === ""
      ) {
        return 1;
      }
      if (
        b.get("tcOut").value === "00:00:00:00" ||
        b.get("ttmlSegmentData").value === ""
      ) {
        return -1;
      }
      const tcinA = getTimeInSecondsFromFrameRate(
        a.get("tcIn").value,
        this.project.frameRate
      );
      const tcinB = getTimeInSecondsFromFrameRate(
        b.get("tcIn").value,
        this.project.frameRate
      );
      return tcinA - tcinB;
    });
  }

  public updateOrAddReply(data: { index: number; reply: ProjectReply }): void {
    if (this.project.replies?.length && this.project.replies[data.index]) {
      this.project.replies[data.index].tcIn = data.reply.tcIn;
      this.project.replies[data.index].tcOut = data.reply.tcOut;
      this.project.replies[data.index].ttmlSegmentData =
        data.reply.ttmlSegmentData;
      this.project.replies[data.index].isSelected = data.reply.isSelected;
    } else {
      this.project.replies?.push({
        projectId: this.project.id,
        uniqueProjectName: this.project.uniqueProjectName,
        tcIn: data.reply.tcIn,
        tcOut: data.reply.tcOut,
        ttmlSegmentData: data.reply.ttmlSegmentData,
        speedRate: 0,
        isLongAudio: false,
        isFarTooLongAudio: false,
      });
    }
    this.projectReplies.emit(this.project.replies);
  }

  public onEventChange(data: { index: number; reply: ProjectReply }): void {
    this.updateOrAddReply(data);
    this.sortRepliesAndControls();
    this.setNextTcInForEvents();

    const replyIndex = this.project.replies.findIndex(
      (reply) =>
        reply.tcIn === data.reply.tcIn && reply.tcOut === data.reply.tcOut
    );
    data.index = replyIndex;
    this.events.controls[data.index].value.isLongAudio = this.isLongAudio(data);
    this.updateControls();

    if (replyIndex !== -1 && !this.project.replies[data.index].id) {
      this.events.controls[data.index].get("isNew").setValue(false);
      this.insertEvent(data.index);
    } else {
      this.updateEvent(data.index);
    }
  }

  private setNextTcInForEvents(): void {
    for (let i = 0; i < this.project.replies?.length; i++) {
      this.project.replies[i].nextTcIn = "";
      if (i !== this.project.replies.length - 1) {
        this.project.replies[i].nextTcIn = this.project.replies[i + 1].tcIn;
      }
    }
  }

  get isAssetProxiesGenerated(): boolean {
    return (
      (this.selectedVideoAsset?.assetProxy?.length > 0 &&
        this.selectedVideoAsset.assetType === "video") ||
      false
    );
  }

  get mins(): string {
    return calculateMinuteDifference(this.lastSavedDateTime, new Date());
  }

  get selectedEventIndex(): number {
    const index = this.events.controls.findIndex(
      (event) => event.value.isSelected
    );
    return index >= 0 ? index : 0;
  }

  get eventTcIn(): any {
    return this.events.controls[this.selectedEventIndex]?.value.tcIn;
  }

  get eventTcOut(): any {
    return this.events.controls[this.selectedEventIndex]?.value.tcOut;
  }

  get isFirstEvent(): boolean {
    if (!this.hasEvents()) {
      return true;
    }
    return this.selectedEventIndex === 0;
  }

  private hasEvents(): boolean {
    return this.events.controls?.length > 0;
  }

  get isLastEvent(): boolean {
    if (!this.hasEvents()) {
      return true;
    }
    return this.selectedEventIndex === this.events.length - 1;
  }

  public moveBackAndForth(backOrForth: string): void {
    if (
      (backOrForth === ActionMove.back && this.isFirstEvent) ||
      (backOrForth === ActionMove.forth && this.isLastEvent)
    ) {
      return;
    } else {
      const index =
        backOrForth === ActionMove.back
          ? this.selectedEventIndex - 1
          : this.selectedEventIndex + 1;
      this.selectEventOnTimeline(index);
    }
  }

  public goToLastEvent() {
    if (this.isLastEvent) {
      return;
    } else {
      this.selectEventOnTimeline(this.events.length - 1);
    }
  }

  public goToFirstEvent() {
    if (this.isFirstEvent) {
      return;
    } else {
      this.selectEventOnTimeline(0);
    }
  }

  private getSeekParameters(
    position: string,
    videoDuration: string
  ): { time: number; maxTime: number } {
    const time = this.selectedVideoAsset?.startTimeCode
      ? getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset.frameRate)
        ) -
        getTimeInSecondsFromFrameRate(
          this.selectedVideoAsset?.startTimeCode,
          Number(this.selectedVideoAsset.frameRate)
        )
      : getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset.frameRate)
        );
    const maxTime = Number(videoDuration);
    return { time: time, maxTime: maxTime };
  }

  private handleEventPlayback(
    action: string,
    time: number,
    index: number
  ): void {
    if (action === "play") {
      // update isBeingPlayed so that we can show the pause icon
      const itemControl = this.events.at(index) as FormGroup;
      let controlValues = {
        ...itemControl.value,
        isBeingPlayed: true,
      };
      if (itemControl && itemControl.value.isFirstPlayback) {
        controlValues = { ...controlValues, isFirstPlayback: false };
      }
      itemControl.patchValue({
        ...controlValues,
      });

      const startFrame = this.selectedVideoAsset.startTimeCode
        ? getTimeInSecondsFromFrameRate(
            this.selectedVideoAsset.startTimeCode,
            Number(this.selectedVideoAsset.frameRate)
          )
        : 0;
      const out =
        getTimeInSecondsFromFrameRate(
          controlValues.tcOut,
          Number(this.selectedVideoAsset.frameRate)
        ) - startFrame;
      const { start, end } = this.playbackDuration(
        itemControl && itemControl.value.isFirstPlayback,
        index === this.prevSelectedEventIndex,
        time,
        out
      );
      this.prevSelectedEventIndex = index;
      this.player.playInterval(start, end);
    } else {
      this.player._player.pause();
    }
  }

  private playbackDuration(
    isFirstPlayback: boolean,
    takeInFromCache: boolean,
    time: number,
    out: number
  ): { start: number; end: number } {
    if (isFirstPlayback || this.player._player.cache_.currentTime === 0) {
      return { start: time, end: out };
    } else {
      const cache = this.player._player.cache_.currentTime;
      if (Math.floor(cache) === Math.floor(out)) {
        return { start: time, end: out };
      }
      return { start: takeInFromCache ? cache : time, end: out };
    }
  }

  private setIsPlayingEvent(isPlaying: boolean) {
    const itemControl = this.events.at(this.selectedEventIndex) as FormGroup;
    let controlValues = {
      ...itemControl.value,
      isBeingPlayed: isPlaying,
    };
    itemControl.patchValue({
      ...controlValues,
    });
  }

  public eventPlaybackChanged(): void {
    this.events.controls.forEach((control) => {
      control.patchValue({
        ...control.value,
        isFirstPlayback: true,
      });
    });
  }

  public syncVideAndTimeLine(event: {
    action: string;
    seekTime: string;
    index: number;
  }): void {
    const { time } = this.getSeekParameters(
      event.seekTime,
      this.selectedVideoAsset.duration
    );
    // do not display pause icon for other events
    this.events.controls.forEach((control) => {
      control.patchValue({
        ...control.value,
        isBeingPlayed: false,
      });
    });
    this.handleEventPlayback(event.action, time, event.index);
  }

  private selectEventOnTimeline(index: number): void {
    this.replysEvents?.selectEventToRecord(index, true);
  }

  public selectEventOnReplyEvent(index: number): void {
    this.timeline?.selectEventToRecord(index);
  }
}
