export function getDefaultTeamsNumberFromEnvironment(environmentName: string) {
    let teamsNumber: number;
    switch (environmentName) {
      case 'prod':
        teamsNumber = 8;
        break;
      case 'dev':
      case 'test':
      case 'staging':
      default:
        teamsNumber = 55; // "Montreal"
        break;
    }
    return teamsNumber.toString();
  }
