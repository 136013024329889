import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Asset } from "@models/asset/asset.model";
import { AssetService } from "./asset.service";
import {
  SearchType,
  Sort,
  StatesLibrary,
  Status,
} from "@models/shared/searchType";
import { Observable, Subject, of } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  debounceTime,
  switchMap,
  distinctUntilChanged,
  tap,
  filter,
  first,
  map,
} from "rxjs/operators";
import { CatalogAssetSelectionService } from "./../shared/catalog-assets-selection.service";
import { ToolsService } from "~/app/shared/tools.service";
import { MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Selection } from "~/app/shared/assets-selection.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmCancelDeleteComponent } from "../shared/components/dialog-confirm-cancel-delete/dialog-confirm-cancel-delete.component";
import { environment } from "~/environments/environment";
import { newUuid } from "~/utils/newUuid";
import { UploadAuthorization } from "@models/shared/upload";
import { FileService } from "~/app/shared/file.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { DurationFormatterService } from "~/app/shared/duration-formatter.service";
import { ConnectWebSocketService } from "../textToSpeech/connectWebSocket.service";
import { ProjectService } from "~/app/textToSpeech/project.service";

import {
  MultiPartUploadHandler,
  Notification,
} from "~/app/shared/multi-part-upload-handler";
import { FnxSelectChipsComponent } from "../shared/components/fnx-select-chips/fnx-select-chips.component";
import { MatTabGroup } from "@angular/material/tabs";
interface FileUploadContext {
  file: File;
  assetId: string; // unique projectName
  upload: {
    remainingTime: number; // estimated remaining time in millis before upload is finished
    status: string; // as red or orange or green
    message: string; // progress (as %) or checksum evaluation, or (network) error ...
    progress: number;
  };
  multiPartUploadHandler?: MultiPartUploadHandler;
  authorization?: UploadAuthorization;
  projectName: string;
  isValidated?: boolean;
  isEdited?: boolean; // edit project scenario
  isDeleted?: boolean; // for undo
}
@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.scss"],
})
export class AssetComponent implements OnInit {
  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;
  public selectedAssets: Asset[] = [];
  public simpleBack = false;
  public originalAssetSelection: Selection;
  public isAssetSelection = false;
  public backLabel: string;
  private assetSelectionSnackBar: MatSnackBarRef<SimpleSnackBar>;
  assets: Asset[];
  public searchTypes = Object.values(SearchType);
  public showClearSearchIcon = false;
  public searchStringEdited = new Subject<string>();
  public resultsByPageList = [25, 50, 100, 200];
  public searchFormGroup: FormGroup;
  public pageFormGroup: FormGroup;
  public responseResults: number = 0;
  public thumbnailsStates = ["show", "hide"];
  public uploadCompleted = false;
  public sorts = [
    {
      label: "alphabetical",
      value: Sort.Alphabetical,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
    {
      label: "createdDate",
      value: Sort.CreatedDate,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
    {
      label: "createdDateDesc",
      value: Sort.CreatedDateDesc,
      parents: [SearchType.Similar, SearchType.Contains, SearchType.BeginsWith],
    },
  ];
  public searchSettings = {
    thumbnailsVisible: true,
    resultsPerPage: this.resultsByPageList[0],
    sortingOrder: this.sorts[2].value,
  };
  public fileUploadContexts: FileUploadContext[] = [];

  public statusValues = [
    StatesLibrary.All,
    StatesLibrary.Deleted,
    StatesLibrary.Failed,
    StatesLibrary.Processing,
    StatesLibrary.Archived,
  ].map((v: any) => ({ label: v, value: v }));

  public frameRates: string[] = [];
  public fileTypes: string[] = [];
  public status = Status;
  public from = 0;

  public filesAllowedWarning = false;
  public displayCongrats = true;
  public recentlyDeletedFile: FileUploadContext;

  @ViewChild("keywordInput")
  protected keywordInputElement: ElementRef;
  @ViewChild("titleResults")
  protected titleResults: ElementRef;

  constructor(
    protected formBuilder: FormBuilder,
    private assetService: AssetService,
    protected assetSelectionService: CatalogAssetSelectionService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toolsService: ToolsService,
    protected fileService: FileService,
    private translateService: TranslateService,
    private cdk: ChangeDetectorRef,
    protected http: HttpClient,
    private durationFormatterService: DurationFormatterService,
    private connectWebSocketService: ConnectWebSocketService,
    private projectService: ProjectService
  ) {}

  async ngOnInit() {
    this.extractBackLabel();
    await this.initSearchFormGroup();
    this.searchFormGroup.updateValueAndValidity();
    this.handleSocket();
  }

  async initSearchFormGroup(): Promise<any> {
    this.pageFormGroup = this.formBuilder.group({
      searchString: "",
      search: this.formBuilder.group({
        keyword: "",
        frameRate: "",
        assetStatus: "",
        fileType: "",
        createdFrom: "",
        createdTo: "",
        searchType: SearchType.Similar,
        sortResults: this.searchSettings.sortingOrder,
        resultsPerPage: this.searchSettings.resultsPerPage,
      }),
    });
    this.searchFormGroup = this.pageFormGroup.get("search") as FormGroup;

    this.searchFormGroup.valueChanges
      .pipe(
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
        filter((_) => this.searchFormGroup.valid),
        tap(() => {
          this.updateClearSearchVisibility();
        }),
        debounceTime(300),
        filter((_) => this.searchFormGroup.valid),
        switchMap(() => this.searchAssets())
      )
      .subscribe((response) => {
        this.manageSearchResponse(response);
      });

    this.searchStringEdited.subscribe((changedString) => {
      this.searchFormGroup.get("keyword").setValue(changedString.trim());
    });
  }

  public searchAssets(): Observable<any> {
    const filters = {
      keyword: this.searchFormGroup.get("keyword").value,
      frameRate: this.searchFormGroup.get("frameRate").value,
      assetStatus: this.searchFormGroup.get("assetStatus").value,
      fileType: this.searchFormGroup.get("fileType").value,
      createdFrom: this.searchFormGroup.get("createdFrom").value,
      createdTo: this.searchFormGroup.get("createdTo").value,
      searchType: SearchType.Contains,
      sortResults: this.searchFormGroup.get("sortResults").value,
      resultsPerPage: this.searchFormGroup.get("resultsPerPage").value,
      from: Math.floor(
        this.from / this.searchFormGroup.get("resultsPerPage").value
      ),
    };
    return this.assetService.getAll(filters);
  }

  public manageSearchResponse(response: any): void {
    this.assets = response.assets;
    this.frameRates.push("All");
    this.frameRates = [
      ...this.frameRates,
      ...response.frameRates
        .map((v: any) => v.frameRate)
        .filter((item: any) => item !== null && item !== undefined),
    ];
    this.fileTypes.push("All");
    this.fileTypes = [
      ...this.fileTypes,
      ...response.fileTypes
        .map((v: any) => v.assetType)
        .filter((item: any) => item !== null && item !== undefined),
    ];
    this.responseResults = response.total || 0;
    this.initSourceFiles();
  }

  public selectSearchType(value: string): void {
    const sort = this.searchFormGroup.get("sortResults").value;

    if (value === SearchType.Similar) {
      this.searchFormGroup.get("sortResults").disable({ onlySelf: true });
    } else {
      this.searchFormGroup.get("sortResults").enable({ onlySelf: true });
    }

    // If the sort option is not a child of the new search type option change the sort to a valid one
    if (
      !this.sorts
        .find((s: any) => s.value === sort)
        .parents.some((p: any) => value === p)
    ) {
      this.searchFormGroup
        .get("sortResults")
        .setValue(
          this.sorts.find((s: any) => s.parents.some((p: any) => p === value))
            .value
        );
    }
  }

  updateClearSearchVisibility(): void {
    this.showClearSearchIcon = !!this.searchFormGroup
      .get("keyword")
      .value.trim();
  }

  public keywordKeyDown(event: KeyboardEvent): boolean {
    // Dirty hack to circumvent compatibility conflict between Safari/Mac and ng Material design
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public clearSearch(): void {
    this.searchFormGroup.patchValue({
      keyword: "",
      frameRate: "",
      assetStatus: "",
      fileType: "",
      createdFrom: "",
      createdTo: "",
      searchType: SearchType.Similar,
      sortResults: this.searchSettings.sortingOrder,
      resultsPerPage: this.searchSettings.resultsPerPage,
    });
    this.pageFormGroup.get("searchString").setValue("");
  }

  public getSortOptions(): any[] {
    const searchType: SearchType = this.searchFormGroup.get("searchType").value;

    return this.sorts.filter((s) => s.parents.some((p) => p === searchType));
  }

  public toggleThumbnails(event: selectionChangeEvent): void {
    this.searchSettings.thumbnailsVisible = event.value;
  }

  public changeFrom(from: number, scrollTo?: boolean): void {
    this.from = from;
    this.assets = undefined;
    if (scrollTo) {
      this.titleResults?.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    this.searchAssets().subscribe((response) => {
      this.manageSearchResponse(response);
    });
  }
  public manageAssetInSelection(asset: Asset) {
    const oldSelection = [...this.selectedAssets];
    const index = this.selectedAssets.findIndex(
      (selectedAsset) => selectedAsset.id === asset.id
    );
    if (index >= 0) {
      asset.isSelected = false;
      this.selectedAssets.splice(index, 1);
    } else {
      asset.isSelected = true;
      this.selectedAssets.push(asset);
    }

    this.assetSelectionService.setSelection({
      assets: this.selectedAssets,
    });

    const changeCount = this.selectedAssets.length - oldSelection.length;
    const itemChangedStr =
      changeCount >= 0 ? "namedItemAdded" : "namedItemRemoved";
    this.toolsService
      .translatedSnackBar(itemChangedStr, "undo", {
        replaceValue: Math.abs(changeCount),
        duration: 3000,
      })
      .pipe(
        tap(
          (ref: MatSnackBarRef<SimpleSnackBar>) =>
            (this.assetSelectionSnackBar = ref)
        ),
        switchMap((ref: MatSnackBarRef<SimpleSnackBar>) => ref.onAction()),
        tap(() => {
          this.selectedAssets = oldSelection;
          this.assetSelectionService.setSelection({
            assets: this.selectedAssets,
          });
        }),
        switchMap(() => {
          return this.toolsService
            .translatedSnackBar("namedItemCanceled", null, {
              duration: 3000,
            })
            .pipe(
              tap(
                (ref: MatSnackBarRef<SimpleSnackBar>) =>
                  (this.assetSelectionSnackBar = ref)
              )
            );
        })
      )
      .subscribe();
  }

  public backFromSelection(): void {
    this.location.back();
  }

  private extractBackLabel() {
    this.route.params
      .pipe(
        first(),
        map((params) => {
          this.backLabel = params.backLabel;
        })
      )
      .subscribe();
  }

  public async initSourceFiles() {
    this.originalAssetSelection = this.assetSelectionService.getSelection();
    await this.initSelection(this.originalAssetSelection, true);
  }

  protected async initSelection(
    assetSelection: Selection,
    showSelectionNavigation = false,
    data?: any
  ) {
    if (data && data.assetsSelection && showSelectionNavigation) {
      this.isAssetSelection = true;
    }

    this.selectedAssets = assetSelection
      ? assetSelection.assets
      : this.selectedAssets;

    // update selectedAsset in assets source.,
    if (this.selectedAssets.length) {
      const assetIndex = this.assets.findIndex(
        (assets) => assets.id === this.selectedAssets[0].id
      );
      if (assetIndex >= 0) {
        this.assets[assetIndex].isSelected = true;
      }
    }
  }

  get isAnyAssetSelected(): boolean {
    return (
      this.assetSelectionService.getSelection()?.assets.length > 0 || false
    );
  }

  public async test(asset: Asset) {}

  public isRemovable(asset: Asset): boolean {
    return asset.status === Status.Completed && !asset.isIngestionFailed
      ? true
      : false;
  }

  public deleteAssets(asset: Asset) {
    const dialogRef = this.dialog.open(DialogConfirmCancelDeleteComponent);
    dialogRef.componentInstance.data = {
      title: "asset.dialogConfirmAssetDeletion.titleSingle",
      assets: [asset],
      confirmLabel: "asset.dialogConfirmAssetDeletion.apply",
      cancelLabel: "asset.dialogConfirmAssetDeletion.cancel",
    };
    dialogRef.afterClosed().subscribe((isDeletionConfirmed) => {
      if (isDeletionConfirmed) {
        this.assetService.deleteAsset(asset.id).subscribe(() => {
          this.searchAssets().subscribe((response) => {
            this.manageSearchResponse(response);
          });
        });
      }
    });
  }

  public goToAssetDetails(asset: Asset): void {
    this.router.navigate([
      "assetsDropBox/details",
      (<any>asset).id,
      {
        backLabel: "asset.backTo",
      },
    ]);
  }

  public async onFileSelected($event: any): Promise<void> {
    this.uploadCompleted = false;
    this.buildFileUploadContexts($event.target.files as unknown as FileList);
    this.cdk.detectChanges();

    await this.prepareFiles();

    this.startUploadingFiles();
  }

  public async dropHandler($event: DragEvent): Promise<boolean> {
    $event.preventDefault();
    if (this.canSelectFiles) {
      this.uploadCompleted = false;
      this.buildFileUploadContexts($event.dataTransfer.files);
      // this.addControls(); // add projectName controls on the go.
      //this.cdk.detectChanges();

      await this.prepareFiles();
      this.startUploadingFiles();
    }
    return of(false).toPromise();
  }

  private buildFileUploadContexts(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      if (
        this.authorizedFileExtensions.includes(
          "." + files[index].name.split(".").pop().toLowerCase()
        )
      ) {
        this.filesAllowedWarning = false;
        const fileContext: FileUploadContext = {
          file: files[index],
          assetId: "",
          upload: {
            remainingTime: Infinity,
            status: "",
            message: "",
            progress: 0,
          },
          projectName: "",
        };
        this.fileUploadContexts.push(fileContext);
      } else {
        this.filesAllowedWarning = true;
        return;
      }
    }
  }

  public get authorizedFileExtensions(): string {
    return environment.project.authorizedFileExtensions.join(", ");
  }
  public async prepareFiles(): Promise<void> {
    this.markExceedingFilesSizeByWarning();
    await this.preProcessUnExceedingFilesSize();
  }
  public markExceedingFilesSizeByWarning() {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (fileUploadContext.file.size > environment.project.maximumFileSize) {
        fileUploadContext.upload.status = Status.FileSizeExceedingFailure;
        fileUploadContext.upload.message = this.translateService.instant(
          "createProject.upload.maxFileSizeReached"
        );
        fileUploadContext.upload.message =
          fileUploadContext.upload.message.replace(
            "{maxFileSizeReached}",
            environment.project.maximumFileSize as unknown as string
          );
      }
    }
  }

  private async preProcessUnExceedingFilesSize() {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (
        fileUploadContext.file.size <= environment.project.maximumFileSize &&
        fileUploadContext.upload.status !== Status.UploadSuccess
      ) {
        await this.preProcessFileUploadContext(fileUploadContext);
      }
    }
  }

  private async preProcessFileUploadContext(
    fileUploadContext: FileUploadContext
  ) {
    const fileExtension = fileUploadContext.file.name.split(".").reverse()[0];
    fileUploadContext.upload.status = Status.Uploading;

    const session = localStorage.getItem("session");
    const parsedSession = JSON.parse(session);

    const uploadAuthorizationBody: any = {
      assetId:
        newUuid() +
        "/" +
        fileUploadContext.file.name.substr(
          0,
          fileUploadContext.file.name.lastIndexOf(".")
        ),
      fileExtension,
      fileSize: fileUploadContext.file.size,
      numberOfFiles: this.fileUploadContexts.length,
      uploadType: "fileUpload",
      tenantId: parsedSession.tenantId,
    };
    fileUploadContext.authorization = await this.fileService
      .createUploadAuthorization(uploadAuthorizationBody)
      .toPromise();
  }

  public get canSelectFiles() {
    return (
      this.fileUploadContexts.length === 0 ||
      (this.fileUploadContexts.length > 0 &&
        this.fileUploadContexts.length ===
          this.fileUploadContexts.filter(
            (fuc) => fuc.upload.status === Status.UploadSuccess
          ).length)
    );
  }

  public async startUploadingFiles(): Promise<void> {
    for (let index = 0; index < this.fileUploadContexts.length; index++) {
      const fileUploadContext = this.fileUploadContexts[index];
      if (
        fileUploadContext.upload.progress === 0 &&
        fileUploadContext.upload.status === Status.Uploading
      ) {
        fileUploadContext.upload.message = "";
        this.startMultiPartUpload(fileUploadContext);
      }
    }
  }

  private processUploadCompleted() {
    this.uploadCompleted = true;
  }

  private startMultiPartUpload(fileUploadContext: FileUploadContext): void {
    fileUploadContext.multiPartUploadHandler = new MultiPartUploadHandler(
      this.fileService,
      this.http,
      {
        notificationHandler: (notification) =>
          this.handleNotification(notification),
        file: fileUploadContext.file,
        uploadAuthorization: fileUploadContext.authorization,
      }
    );
  }
  private get areFilesAllUploaded(): boolean {
    let allFilesUploaded = false;
    if (this.fileUploadContexts.length > 0) {
      allFilesUploaded =
        this.fileUploadContexts.filter(
          (fuc) => fuc.upload.status !== Status.UploadSuccess
        ).length === 0;
    }
    return allFilesUploaded;
  }

  private handleNotification(notification: Notification): void {
    const foundFileUploadContext = this.fileUploadContexts.find(
      (fileUploadContext) =>
        fileUploadContext.file &&
        fileUploadContext.file.name === notification.fileName
    );
    if (!foundFileUploadContext) {
      // might have been removed from the list meanwhile
      return;
    }
    if (notification.status === "progression") {
      foundFileUploadContext.upload.progress = notification.value;
      foundFileUploadContext.upload.status = Status.Uploading;
      foundFileUploadContext.upload.message = "";
    } else if (notification.status === "remainingTime") {
      foundFileUploadContext.upload.remainingTime = notification.value;
      foundFileUploadContext.upload.status = Status.Uploading;
    } else if (notification.status === "completionTime") {
      foundFileUploadContext.upload.message = this.translateService.instant(
        "createProject.upload.completed"
      );
      foundFileUploadContext.upload.status = Status.UploadSuccess;
    } else {
      /* if (notification.status === 'networkIssue') */
      const networkIssue = this.translateService.instant(
        "createProject.upload.networkIssueRetry"
      );
      foundFileUploadContext.upload.message = networkIssue.replace(
        "{networkIssueRetryInterval}",
        notification.value.toString()
      );
      foundFileUploadContext.upload.status = Status.UploadFailure;
    }
    if (this.areFilesAllUploaded) {
      this.processUploadCompleted();
    }
  }
  public get canDisplayFilesListing() {
    return this.fileUploadContexts.length > 0;
  }
  public get unRemovedFileUploadContexts() {
    return this.fileUploadContexts.filter((files) => !files.isDeleted);
  }
  hasFileAttached() {
    return this.fileUploadContexts.filter((x) => !x.isDeleted).length > 0;
  }
  public getFormattedDurationMessage(fileContext: FileUploadContext): string {
    return this.durationFormatterService.getUserReadableMessage(
      fileContext.upload.remainingTime,
      "createProject.upload",
      "remainingTime"
    );
  }

  public get canDisplayUploadCongrats(): boolean {
    return this.uploadCompleted || this.areFilesAllUploadedOrCanceled;
  }

  private get areFilesAllUploadedOrCanceled(): boolean {
    return this.uploadCompleted;
  }

  handleSocket() {
    this.connectWebSocketService.connectToAssetsSocket();
    this.connectWebSocketService.assetSocketMessages.subscribe(
      (updatedAsset: Asset) => {
        const parsedAsset = updatedAsset;
        const session = localStorage.getItem("session");
        const parsedSession = JSON.parse(session);
        const tenantId = parsedSession.tenantId;

        if (parsedAsset.tenantId == tenantId) {
          const index = this.assets.findIndex(
            (asset) => asset.id === parsedAsset.id
          );
          if (index !== -1) {
            if (parsedAsset.status === Status.UploadCancelled) {
            } else {
              this.assets[index] = parsedAsset;
            }
          } else {
            this.assets.unshift(parsedAsset);
          }
        }
        this.cdk.detectChanges();
      }
    );
  }

  goToTab(index?: number): void {
    this.tabGroup.selectedIndex = index || 0;
    this.uploadCompleted = false;
    this.fileUploadContexts = [];
  }

  public cancelSingleFileUpload(fileUploadContext: FileUploadContext) {
    fileUploadContext.isDeleted = true;
    this.deleteFiles(fileUploadContext);
    this.recentlyDeletedFile = fileUploadContext;
  }

  deleteFiles(fileUploadContext: FileUploadContext) {
    setTimeout(async () => {
      if (fileUploadContext.isDeleted) {
        const index = this.fileUploadContexts.findIndex(
          (fuc) => fuc.file.name === fileUploadContext.file.name
        );
        this.fileUploadContexts.splice(index, 1);
        if (fileUploadContext.upload.status === Status.UploadSuccess) {
          await this.deleteFile(fileUploadContext.assetId);
        }
      }
    }, environment.snackBarDurationLong + 500);
  }

  private async deleteFile(cancelledFileAssetId: string): Promise<void> {
    await this.projectService.discardFile([cancelledFileAssetId]).toPromise();
  }
}
