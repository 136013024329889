import { ReplyService } from "~/app/textToSpeech/reply.service";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  Project,
  ProjectReply,
  ReplyStatus,
} from "@models/textToSpeech/project.model";
import {
  getTimeInSecondsFromFrameRate,
  splitTextIntoLines,
} from "~/utils/project";
import { ProjectService } from "../../project.service";
import { RecordingWorkspaceComponent } from "../recording-workspace/recording-workspace";
import { HttpErrorResponse } from "@angular/common/http";
import { Asset } from "@models/asset/asset.model";
import { ShortcutService } from "~/app/shared/shortcut.service";
import { Subscription } from "rxjs";
import { Action, Module } from "@models/shortcut/shortcut.model";

@Component({
  selector: "app-recording",
  templateUrl: "./recording.component.html",
  styleUrls: ["./recording.component.scss"],
})
export class RecordingComponent implements OnInit, AfterViewInit {
  @ViewChild(RecordingWorkspaceComponent)
  public recordingPart: RecordingWorkspaceComponent;

  @Input() uniqueProjectName: string;
  @Input() project: Project;
  @Input() microphoneDeviceId: string;
  @Input() selectedVideoAsset: Asset;

  @Output() emitSetupGuideChange = new EventEmitter<any>();
  @Output() updateProjectFromRecording = new EventEmitter<any>();

  @ViewChild("parentContainer") parentContainer!: ElementRef;
  @ViewChild("player")
  private player: any;

  public replies: ProjectReply[] = [];
  public showRecording = true;
  public showHideRetakes = true;
  public isEventSelected = false;
  public selectedReply: ProjectReply;
  public spinner = true;
  public currentSelectedReply = 0;
  public prevSelectedReply = 0;

  private isVideoSectionVisible = false;
  private shortcutTriggeredSubscription: Subscription;
  public isShortcutsTabsActive: boolean;
  private module = Module.Recording;
  private isCutModeEnabled = false;
  isPlaying: boolean = false;
  private selectedRetakeIndex = 0;
  private selectedIcons: number[] = [];
  private videoIsReady = false;

  constructor(
    private projectService: ProjectService,
    private cdk: ChangeDetectorRef,
    private replyService: ReplyService,
    private keyboardShortcutService: ShortcutService
  ) {}

  processIndex(index: number) {
    return index < 10 ? "0" + index : index;
  }

  setShowRecording() {
    this.showRecording = !this.showRecording;
  }

  private selectFirstByDefault(index: number = 0): void {
    if (this.currentSelectedReply !== index + 1) {
      this.unSelectReplies();
      this.replies[index].isSelected = true;
      this.selectedReply = this.replies[index];
      this.currentSelectedReply = index + 1;
      this.scrollToShowHighlightedSection();
    }
  }

  public updateSelectedRetake(index: number): void {
    this.selectFirstByDefault(index);
  }

  private scrollToShowHighlightedSection(): void {
    // Assuming you have an array of child elements or some logic to identify the selected element
    const selectedElement: any =
      document.getElementsByClassName("selected-retake")[0];
    // Scroll the parent container to bring the selected element into view
    if (selectedElement) {
      const parent = this.parentContainer.nativeElement;
      const selectedElementTop = selectedElement.offsetTop;
      const selectedElementHeight = selectedElement.offsetHeight;
      const parentScrollTop = parent.scrollTop;
      const parentHeight = parent.offsetHeight;
      // Calculate the scroll position to center the selected element
      const scrollTo =
        selectedElementTop - (parentHeight - selectedElementHeight) / 2;
      parent.scrollTop = scrollTo;
    }
  }

  setReplies() {
    this.replies = this.project.replies;
    this.spinner = false;
    if (this.replies?.length) {
      this.selectFirstByDefault();
    }
  }

  ngOnInit(): void {
    this.replies = this.project.replies;
    this.spinner = false;
    if (this.replies?.length) {
      this.initSelectValue();
      this.selectedReply = this.replies[0];
    }
  }

  ngAfterViewInit(): void {
    this.setReplies();
    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.OpenCloseStudio) {
          this.setShowRecording();
        } else if (shortcut === Action.PlayPauseSelectedEvent) {
          this.playPauseRecording(this.currentSelectedReply - 1);
        } else if (shortcut === Action.NextEvent) {
          this.selectFirstByDefault(this.currentSelectedReply);
        } else if (shortcut === Action.PreviousEvent) {
          this.selectFirstByDefault(this.currentSelectedReply - 2);
        } else if (shortcut === Action.GoToTop) {
          this.selectFirstByDefault(0);
        } else if (shortcut === Action.GoToBottom) {
          this.selectFirstByDefault(this.replies?.length - 1);
        } else if (shortcut === Action.OpenVideoPlayer) {
          this.toggleVideoSectionView();
        } else if (shortcut === Action.PlayAllEvents) {
          if (this.isPlaying === false) {
            this.isPlaying = true;
            this.playAllRecording(this.currentSelectedReply - 1);
          } else {
            this.playPauseRecording(this.currentSelectedReply - 1);
            this.isPlaying = false;
          }
        }
      });
    this.isVideoSectionVisible = !!this.selectedVideoAsset;
  }

  public ngOnDestroy(): void {
    this.shortcutTriggeredSubscription?.unsubscribe();
  }

  viewHideRetakes(showHideRetakesSection: boolean) {
    this.showHideRetakes = showHideRetakesSection;
  }

  selectEventToRecord(index: number) {
    if (this.isCutModeEnabled) {
      return;
    }
    this.selectFirstByDefault(index);
  }

  private unSelectReplies(): void {
    for (let index = 0; index < this.replies.length; index++) {
      this.replies[index].isSelected = false;
    }
  }

  splitEvent(event: string) {
    return event?.length > 75 ? splitTextIntoLines(event, 75) : [event];
  }

  updateRecordedReply(recordedReply: ProjectReply) {
    const index = this.replies.findIndex(
      (reply) => reply.id === recordedReply.id
    );
    this.replies[index].voiceRecording = recordedReply.voiceRecording;
    this.replies[index].selectedRetakeIndex = recordedReply.selectedRetakeIndex;
    const projectDto = this.buildProjectDto(this.replies[index]);

    const formData = new FormData();
    formData.append(
      "file",
      projectDto.replies[0].voiceRecording.get("audio-file")
    );
    formData.append("data", JSON.stringify(projectDto));
    this.replyService.saveVoiceRecording(formData).subscribe(
      (data: ProjectReply) => {
        if (data?.audioFilePath) {
          data.voiceRecording = formData.get("file");
          delete data.contentData;
          this.replies[index] = data;
          this.project.replies[index] = data;
          if (typeof data.multipleRetakes === "string") {
            this.project.replies[index].multipleRetakes = JSON.parse(
              data.multipleRetakes as string
            );
          }
          this.updateProjectFromRecording.emit(this.project);
        }
      },
      (error: HttpErrorResponse) => {
        this.replies[index].status = ReplyStatus.Error;
        this.replies[index].error = ReplyStatus.Error;
      },
      () => {
        this.cdk.detectChanges();
      }
    );
  }

  buildProjectDto(reply?: ProjectReply) {
    return {
      id: this.project.id,
      userId: this.project.userId,
      projectNames: [this.project.name],
      uniqueProjectNames: [this.project.uniqueProjectName],
      language: this.project.language,
      region: this.project.region,
      voice: this.project.voice,
      lexiconUri: this.project.lexiconUri,
      startTimeCode: this.project.startTimeCode,
      speedRate: reply.speedRate,
      usersToNotify: [this.project.userId],
      isRestarting: false,
      isAutomatedSpeed: this.project.isAutomatedSpeedRate,
      isAutomatedMix: this.project.isAutomatedMix,
      automatedSpeedRate: this.project.automatedSpeedRate,
      replies: [reply],
      frameRate: this.project.frameRate,
      stereoFilePath: this.project.stereoFilePath || "",
      voiceRecordingEnabled: true,
      ttmlFilePath: this.project.ttmlFilePath,
    };
  }

  playPauseRecording(index: number, isPlayAll = false) {
    if (this.isCutModeEnabled) {
      return;
    }

    if (this.prevSelectedReply !== index) {
      this.setIsRecording(this.prevSelectedReply, false);
      this.prevSelectedReply = index;
    }

    if (this.replies[index].isRecordingBeingPlayed) {
      this.setIsRecording(index, true);
      if (this.isVideoSectionVisible) {
        this.handleEventPlayback(index);
        this.player._player.on("playing", () => {
          this.player._player.off("playing");
          this.recordingPart.playPauseRecording();
        });
      } else {
        this.recordingPart.playPauseRecording();
      }
    } else {
      this.selectEventToRecord(index);
      this.setIsRecording(index, true);
      setTimeout(() => {
        if (this.isVideoSectionVisible) {
          this.handleEventPlayback(index);
          this.player._player.on("playing", () => {
            this.player._player.off("playing");
            this.recordingPart.playPauseRecording();
          });
        } else {
          this.recordingPart.playPauseRecording();
        }
        if (!isPlayAll) {
          const onFinish = () => {
            this.setIsRecording(index, false);
          };
          this.recordingPart.wavesurfer.once("finish", onFinish);
        }
      }, 500);
    }
  }

  playPauseAllRecording(index: number) {
    if (this.isCutModeEnabled) {
      return;
    }
    this.setIsRecording(index, true);
    this.replies[index].isSelected = true;
    this.prevSelectedReply = index;
    if (this.isVideoSectionVisible) {
      this.handleEventPlayback(index);
      this.player._player.on("playing", () => {
        this.player._player.off("playing");
        this.recordingPart.playPauseRecording();
      });
    } else {
      this.recordingPart.playPauseRecording();
    }
  }

  setIsPlaying(isPlaying: boolean) {
    if (this.isCutModeEnabled) {
      return;
    }
    this.isPlaying = isPlaying;
  }

  playAllRecording(index: number) {
    if (this.isCutModeEnabled) {
      return;
    }
    const isPlaying = this.isPlaying;
    if (index <= this.replies?.length - 1 && isPlaying) {
      if (this.replies[index].audioFilePath) {
        this.selectEventToRecord(index);
        setTimeout(() => {
          this.playPauseAllRecording(index);
          const test = () => this.onEnded(index);
          this.recordingPart.wavesurfer.on("finish", test);
        }, 200);
      } else {
        this.replies[index].isSelected = false;
        this.setIsRecording(index, false);
        this.playAllRecording(index + 1);
      }
    } else {
      this.isPlaying = false;
    }
  }

  private onEnded = (index: number) => {
    this.selectEventToRecord(index + 1);
    this.replies[index].isSelected = false;
    this.setIsRecording(index, false);
    setTimeout(() => {
      this.playAllRecording(index + 1);
    }, 200);
  };

  private handlerEnded = () => {
    this.selectFirstByDefault(this.currentSelectedReply);
    this.playAllRecording(this.currentSelectedReply);
  };

  setIsRecording(index: number, isRecordingBeingPlayed: boolean) {
    this.replies[index].isRecordingBeingPlayed = isRecordingBeingPlayed;
    this.cdk.detectChanges();
  }

  public openSetupGuide(deviceId: string): void {
    this.emitSetupGuideChange.emit(deviceId);
  }

  get projectFrameRate(): number {
    return this.project?.frameRate || 0;
  }

  public toggleVideoSectionView(): void {
    this.isVideoSectionVisible = !this.isVideoSectionVisible;
  }

  get videoSectionVisible(): boolean {
    return this.isVideoSectionVisible;
  }

  public showShortcutsTabs() {
    this.isShortcutsTabsActive = !this.isShortcutsTabsActive;
  }

  public exitShortcutsSetup() {
    this.isShortcutsTabsActive = false;
  }

  public startRecording() {
    if (this.isCutModeEnabled) {
      return;
    }
    this.recordingPart.recordVoice(true);
  }

  get latestAudioFilePath(): string {
    const filePath = this.project.replies
      .find((reply) => reply.id === this.selectedReply.id)
      ?.audioFilePath?.split(`${this.selectedReply.uniqueProjectName}/`)[1];
    return filePath && filePath !== undefined
      ? this.project.replies.find((reply) => reply.id === this.selectedReply.id)
          ?.audioFilePath
      : "";
  }

  public switchCutContentOperation(isCutModeEnabled: boolean): void {
    this.isCutModeEnabled = isCutModeEnabled;
  }

  private setSelectedRetakeIndex(i: number) {
    this.selectedRetakeIndex = i;
  }

  private initSelectValue() {
    for (let index = 0; index < this.replies.length; index++) {
      this.selectedIcons[index] = this.getSelectedRetake(index);
    }
  }

  private getSelectedRetake(index: number) {
    let multipleRetakesIndex = 0;
    if (
      this.replies[index].multipleRetakes &&
      this.replies[index].audioFilePath
    ) {
      multipleRetakesIndex = (
        this.replies[index].multipleRetakes as string[]
      ).findIndex((retake) => retake === this.replies[index].audioFilePath);
    }
    return multipleRetakesIndex === -1 ? 0 : multipleRetakesIndex;
  }

  public getIconRetake(value: number) {
    return value === 0 || !value ? "take_original" : "take_alternate";
  }

  private handleEventPlayback(index: number, recording = false): void {
    const { time } = this.getSeekParameters(
      this.selectedReply.tcIn,
      this.selectedVideoAsset.duration
    );
    if (this.replies[index].isRecordingBeingPlayed || recording) {
      const startFrame = this.selectedVideoAsset.startTimeCode
        ? getTimeInSecondsFromFrameRate(
            this.selectedVideoAsset.startTimeCode,
            Number(this.selectedVideoAsset.frameRate)
          )
        : 0;
      const out =
        getTimeInSecondsFromFrameRate(
          this.selectedReply.tcOut,
          Number(this.selectedVideoAsset.frameRate)
        ) - startFrame;
      const { start, end } = this.playbackDuration(
        this.isPlaying,
        index === this.currentSelectedReply,
        time,
        out
      );
      this.player.playInterval(start, end);
    } else {
      this.player._player.pause();
    }
  }

  private playbackDuration(
    isFirstPlayback: boolean,
    takeInFromCache: boolean,
    time: number,
    out: number
  ): { start: number; end: number } {
    if (isFirstPlayback || this.player._player.cache_.currentTime === 0) {
      return { start: time, end: out };
    } else {
      const cache = this.player._player.cache_.currentTime;
      if (Math.floor(cache) === Math.floor(out)) {
        return { start: time, end: out };
      }
      return { start: takeInFromCache ? cache : time, end: out };
    }
  }

  private getSeekParameters(
    position: string,
    videoDuration: string
  ): { time: number; maxTime: number } {
    const time = this.selectedVideoAsset?.startTimeCode
      ? getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset.frameRate)
        ) -
        getTimeInSecondsFromFrameRate(
          this.selectedVideoAsset?.startTimeCode,
          Number(this.selectedVideoAsset.frameRate)
        )
      : getTimeInSecondsFromFrameRate(
          position,
          Number(this.selectedVideoAsset.frameRate)
        );
    const maxTime = Number(videoDuration);
    return { time: time, maxTime: maxTime };
  }

  get startTimeCode(): string {
    return this.project.startTimeCode !== "00:00:00:00"
      ? this.project.startTimeCode.replace(";", ":")
      : this.selectedVideoAsset.startTimeCode
      ? this.selectedVideoAsset.startTimeCode.replace(";", ":")
      : "00:00:00:00";
  }
}
