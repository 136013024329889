import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ShortcutAction, UserShortcut } from "@models/shortcut/shortcut.model";
import { TranslateService } from "@ngx-translate/core";
import { ShortcutService } from "../../shortcut.service";
import { DialogShortcutComponent } from "../dialog-shortcut/dialog-shortcut.component";

@Component({
  selector: "app-shortcuts-tabs",
  templateUrl: "./shortcuts-tabs.component.html",
  styleUrls: ["./shortcuts-tabs.component.scss"],
})
export class ShortcutsTabsComponent implements OnInit {
  @Output() exitSetup = new EventEmitter();
  @Input() module: string;
  public pageTitle = "";
  public actions: any[];
  public categories: string[];
  constructor(
    private keyboardShortcutService: ShortcutService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initShortcuts();
  }

  private initShortcuts() {
    this.keyboardShortcutService
      .getAllActionsByModule(this.module)
      .subscribe((actions) => {
        this.actions = actions;
        this.categories = Object.keys(this.actions);
      });
  }

  public exitShortcutsSetup() {
    this.exitSetup.emit();
  }

  private openDialogShortcut(action: ShortcutAction): void {
    const dialogRef = this.dialog.open(DialogShortcutComponent);
    dialogRef.componentInstance.data = {
      title: "scriptWriting.ShortcutsDialogTitle",
      confirmLabel: "scriptWriting.confirm",
      cancelLabel: "scriptWriting.cancel",
      action: action,
    };
    dialogRef.afterClosed().subscribe((data) => {
      this.configureShortcuts(data.shortcut, data.action);
    });
  }

  configureShortcuts(shortcut: string, action: ShortcutAction) {
    if (shortcut) {
      this.keyboardShortcutService
        .setUserShortcut(shortcut, action, this.module)
        .subscribe(() => {
          this.initShortcuts();
        });
    }
  }

  public getKeys(userShortcuts: UserShortcut[]) {
    if (userShortcuts.length && userShortcuts[0].shortcutKeys) {
      return userShortcuts[0].shortcutKeys.split("+");
    } else {
      return [];
    }
  }
}
