import { EventEmitter } from "@angular/core";
import { UploadAuthorization } from "@models/shared/upload";
import { FileService } from "~/app/shared/file.service";
import {
  HttpClient,
  HttpProgressEvent,
  HttpEventType,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { empty } from "rxjs";

export interface Notification {
  status: "progression" | "completionTime" | "remainingTime" | "networkIssue";
  value: number; // maybe a time duration in ms, or a %age, or a number of re-try
  fileName: string;
}

interface ChunkContext {
  state: "free" | "taken" | "over";
  uploaded: number; // how much data (quantity) has been uploaded in Bytes,
  signedUrl: string; // signed url where you need to upload the part
}

type Etag = string;

export class MultiPartUploadHandler {
  public notifier = new EventEmitter<Notification>();
  public chunkSize = 1024 * 1024 * 10; // 10MB chunks. 5MB is the minimum supported by S3,
  public maxParallelUploadsByChunks = 3;
  public networkRetryInterval = 10;
  public s3bucket: any;
  public file: File;
  public startTime: number;
  public eTags: Etag[] = [];
  public chunkContexts: ChunkContext[] = [];
  public aborted = false;
  public uploadAuthorization: UploadAuthorization;
  constructor(
    protected fileService: FileService,
    protected http: HttpClient,
    protected params: {
      notificationHandler: (notification: Notification) => void;
      file: File;
      uploadAuthorization: UploadAuthorization;
    }
  ) {
    this.file = params.file;
    this.uploadAuthorization = params.uploadAuthorization;
    this.notifier.subscribe((notification: Notification) =>
      params.notificationHandler(notification)
    );
    this.startMultipart();
  }

  public async abortMultipartUpload(): Promise<void> {
    this.aborted = true;

    this.fileService.abortUpload(
      this.uploadAuthorization.s3Key,
      this.uploadAuthorization.uploadId
    );
  }

  public startMultipart(): void {
    const session = localStorage.getItem("session");
    const parsedSession = JSON.parse(session);
    this.startTime = new Date().getTime();
    this.uploadAuthorization.tenantId = parsedSession.tenantId;
    this.chunkContexts = this.uploadAuthorization.signedUrls.map((url) => ({
      state: "free" as "free",
      uploaded: 0,
      signedUrl: url,
    }));

    for (
      let channelIndex = 0;
      channelIndex <= this.maxParallelUploadsByChunks;
      channelIndex++
    ) {
      if (this.chunkContexts.length > channelIndex) {
        this.processSinglePart(channelIndex);
      }
    }
  }

  public async processSinglePart(channelIndex: number): Promise<void> {
    if (this.aborted) {
      return;
    }

    this.chunkContexts[channelIndex].state = "taken";
    this.updateProgress();
    const end = Math.min(
      channelIndex * this.chunkSize + this.chunkSize,
      this.file.size
    );
    this.http
      .request("PUT", this.chunkContexts[channelIndex].signedUrl, {
        body: this.file.slice(channelIndex * this.chunkSize, end),
        observe: "events",
        reportProgress: true,
      })
      .pipe(
        catchError((error) => {
          console.log("Error! in AWS S3 uploadPart", error);
          this.processNetworkIssue(channelIndex);
          return empty();
        })
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.chunkContexts[channelIndex].uploaded = (
            event as HttpProgressEvent
          ).loaded;
          return this.updateProgress();
        } else if (event.type === HttpEventType.Response) {
          return this.completeSinglePart(event, channelIndex);
        }
      });
  }

  public processNetworkIssue(channelIndex: number) {
    if (this.aborted) {
      return;
    }
    console.log("Error, retry in " + this.networkRetryInterval + " sec");
    this.notifier.emit({
      status: "networkIssue",
      value: this.networkRetryInterval,
      fileName: this.file.name,
    });
    setTimeout(
      () => this.processSinglePart(channelIndex),
      this.networkRetryInterval * 1000
    );
  }

  public async completeSinglePart(
    mData: any,
    channelIndex: number
  ): Promise<void> {
    if (this.aborted) {
      return;
    }
    this.eTags[channelIndex] = mData.headers.get("etag");
    this.chunkContexts[channelIndex].state = "over";

    if (
      !this.chunkContexts.some(
        (toUploadChunkState: any) => toUploadChunkState.state !== "over"
      )
    ) {
      await this.completeMultipart();
    } else {
      const nextPart = this.chunkContexts.findIndex(
        (chunkState: any) => chunkState.state === "free"
      );
      if (nextPart !== -1) {
        await this.processSinglePart(nextPart);
      } // No next part to take, kill worker
    }
  }

  public async completeMultipart(): Promise<void> {
    if (this.aborted) {
      return;
    }

    await this.fileService
      .finishUpload(
        this.uploadAuthorization.s3Key,
        this.uploadAuthorization.uploadId,
        this.eTags
      )
      .toPromise();

    const endTime = new Date().getTime();
    this.notifier.emit({
      status: "completionTime",
      value: endTime - this.startTime,
      fileName: this.file.name,
    });
  }

  // update progression UI
  public updateProgress(): void {
    if (this.aborted) {
      return;
    }

    const totalLoaded = this.chunkContexts.reduce(
      (acc: number, channelCtxt: ChunkContext) => acc + channelCtxt.uploaded,
      0
    );
    const progressionPercentage = Math.floor(
      (totalLoaded / this.file.size) * 100
    );
    this.notifier.emit({
      status: "progression",
      value: progressionPercentage,
      fileName: this.file.name,
    });

    const elapsed = new Date().getTime() - this.startTime;
    const estimatedTotalTime = (100 / progressionPercentage) * elapsed;
    this.notifier.emit({
      status: "remainingTime",
      value: estimatedTotalTime - elapsed,
      fileName: this.file.name,
    });
  }
}
