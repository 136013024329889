import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Asset } from "@models/asset/asset.model";
import { ListItemAction } from "../action-list-item/action-list-item.model";
import { MatDialog } from "@angular/material/dialog";
import { DialogPlayerComponent } from "~/app/shared/components/dialog-player/dialog-player.component";
import { TranslateService } from "@ngx-translate/core";
import {
  processingStatus,
  Status,
  StatesLibrary,
} from "@models/shared/searchType";
@Component({
  selector: "asset-list-element",
  templateUrl: "./asset-list-element.component.html",
  styleUrls: ["./asset-list-element.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetListElementComponent implements OnInit, OnChanges {
  @Input() possibleAsset: Asset;
  @Input() thumbnailsVisible: boolean;
  @Input() showManage: boolean = false;
  @Input() isRemovable: boolean | "disabled";
  @Input() isCancellable = true;
  @Input() iconTypeVisible = true;
  @Input() isSelected: boolean;
  @Input() noDefaultActions: boolean;
  @Output() cancelAsset = new EventEmitter<Asset>();
  @Input() showSpinner: boolean;
  @Input() isAssetSelectionAllowed: boolean;
  @Input() disableAssetSelection: boolean;
  @Input() parent: string;

  @ViewChild("extendedErrorMessage")
  private extendedErrorElement: ElementRef;

  @Output() manageAssetInSelection = new EventEmitter<Asset>();
  @Output() assetViewClick = new EventEmitter<Asset>();
  @Output() assetDeleteClick = new EventEmitter<Asset>();

  public assetIcon: string;
  public assetType: string;
  public actions: ListItemAction[];
  public cancelActions: ListItemAction[] = [];
  public asset: Asset;
  public hasCatalogPlayVideoPermission = false;
  public canDownload = false;
  public canDeleteOwnFailedIngestedContent = false;
  public canDeleteOtherFailedIngestedContent = false;
  public canDeleteFailedSourceFilesCatalog = false;
  public myUserId: string;
  public isPublishRoute: boolean;
  public supportEmail: string;
  public isExtendedMessageDisplayed: boolean;
  public isRedMoreActivated: boolean;
  public isDev: boolean;
  public thumbnail: string;
  public noThumbnailUrl = "assets/asset_no_thumb.jpg";

  constructor(
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    protected changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.initializeAsset();
    this.setActions();
  }

  ngOnInit() {
    this.initializeAsset();
    this.setActions();
  }

  private initializeAsset() {
    if (this.possibleAsset) {
      this.asset = this.possibleAsset as Asset;
      this.thumbnail = this.asset.thumbnail || this.noThumbnailUrl;
    }
  }

  public seeDetails() {
    this.assetViewClick.emit(this.possibleAsset);
  }

  public deleteAsset() {
    this.assetDeleteClick.emit(this.possibleAsset);
  }

  public getDuration(): string {
    return this.asset.duration;
  }

  public play(): void {
    const dialogRef = this.dialog.open(DialogPlayerComponent, {
      width: "55%",
      minWidth: "45%",
    });
    dialogRef.componentInstance.data = {
      asset: this.possibleAsset as Asset,
      detailsLabel: "asset.details",
      closeLabel: "asset.close",
    };
    dialogRef.afterClosed().subscribe((goToDetails: boolean) => {
      if (!!goToDetails) {
        this.seeDetails();
      }
    });
  }

  public addToSelection(): void {
    this.possibleAsset.isSelected = true;
    this.manageAssetInSelection.emit(this.possibleAsset);
  }

  public removeFromSelection(): void {
    this.possibleAsset.isSelected = false;
    this.manageAssetInSelection.emit(this.possibleAsset);
  }

  public isDeleted() {
    return this.asset && !!this.asset.isDeleted;
  }

  isPublishIngestionFailed(): boolean {
    return this.asset.isIngestionFailed;
  }

  /**
   * Set action button using icons
   * as defined per material design https://fonts.google.com/icons?selected=Material+Icons
   * or curstom icon defined in app.component.ts customIcons
   * @returns void
   */
  public setActions(): void {
    if (this.parent === "dialog-confirm-cancel-delete") {
      this.actions = [];
      this.iconTypeVisible = false;
      return;
    }

    if (this.isAssetSelectionAllowed) {
      this.actions = [];
      this.addAssetDetailsAndPlayButton();
      if (this.canPlayVideo) {
        this.actions.push({
          icon: "plus",
          label: "asset.add",
          disabled: this.disableAssetSelection,
          callback: this.addToSelection.bind(this),
        });
      }
      if (this.isSelected) {
        const index = this.actions.findIndex((action) => action.icon == "plus");
        this.actions[index] = {
          label: "asset.remove",
          icon: "remove",
          disabled: this.isRemovable === "disabled",
          callback: this.removeFromSelection.bind(this),
        };
      }
    } else {
      this.actions = [];
      this.addAssetDetailsAndPlayButton();
      if (this.isRemovable && !this.asset.isDeleted) {
        this.actions.push({
          icon: "delete",
          label: "asset.delete",
          disabled: this.isRemovable === "disabled",
          callback: this.deleteAsset.bind(this),
        });
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  private addAssetDetailsAndPlayButton(): void {
    if (this.canPlayVideo) {
      this.actions.push({
        icon: "play",
        label: "asset.playVideo",
        callback: this.play.bind(this),
      });
    }

    if (this.isRemovable) {
      this.actions.push({
        icon: "detailsPage",
        label: "asset.details",
        callback: this.seeDetails.bind(this),
      });
    }
  }

  private get canPlayVideo(): boolean {
    return this.asset.isPlayable && !this.asset.isDeleted;
  }

  canDisplayStatus(): boolean {
    return (
      this.asset && !this.asset.isDeleted && this.asset.status !== "success"
    );
  }

  onClickAction(e: any, action: ListItemAction): void {
    e.stopPropagation();

    if (action.callback) {
      action.callback();
    }
  }

  public get assetStatus() {
    if (this.asset.isIngestionFailed) {
      return StatesLibrary.Failed;
    }
    if (this.asset.status === Status.Completed) {
      return "";
    }
    if (processingStatus.includes(this.asset.status as Status)) {
      if (
        this.asset.status === Status.StartTranscode ||
        (this.asset.status === Status.CheckTranscodeStatus &&
          !this.asset.transcodeProgress)
      ) {
        return this.translateService.instant(
          "asset.fileStatus." + this.kebabToCamelCase(Status.StartTranscode)
        );
      } else if (
        this.asset.status === Status.CheckTranscodeStatus &&
        this.asset.transcodeProgress
      ) {
        return (
          this.translateService.instant(
            "asset.fileStatus.transcodeProgressionPercentage"
          ) +
          " " +
          this.asset.transcodeProgress +
          "%"
        );
      }
      return this.kebabToCamelCase(this.asset.status);
    } else if (!this.asset.status && this.asset.checksumValue) {
      return Status.New;
    }
    return this.translateService.instant(this.asset.status);
  }

  private kebabToCamelCase(str: string): string {
    return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
  }
}
